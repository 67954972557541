import { computed, defineComponent, reactive, ref, onUnmounted, watch } from '@vue/composition-api';
import { SystemServiceType, useGetVenueQuery } from '@/generated-types/graphql.types';
import SectionHero from '@/components/SectionHero.vue';
import ImageViewer from '@/components/ImageViewer.vue';
import SectionDetails from '@/Venue/components/SectionDetails.vue';
import SectionLocation from '@/components/SectionLocation.vue';
import ServiceList from '@/Venue/components/ServiceList.vue';
import TagsList from '@/Tag/TagsList.vue';
import VenueRules from '@/pages/customer/VenueRules.vue';
import EventBus from '@/shared/services/eventBus';
import useStructuredData from '@/shared/composables/useStructuredData';
import SocialLocationModal from '@/components/SocialLocationModal.vue';
import { useMeta } from '@/shared/composables/useMeta';
import { SITE_URL } from '@/App.vue';
import { venueSchemaOrgInfo } from '@/Venue/venue.seo';
export default defineComponent({
    components: {
        ImageViewer,
        SectionDetails,
        SectionHero,
        ServiceList,
        SectionLocation,
        SocialLocationModal,
        TagsList,
        VenueRules
    },
    setup(_, context) {
        const { root } = context;
        const venue = ref(undefined);
        const venueTagsVisible = ref(false);
        const getVenueQueryVariables = reactive({
            slug: ''
        });
        const getVenueQueryOptions = reactive({
            enabled: false,
            fetchPolicy: 'no-cache'
        });
        const { injectStructuredData, ejectStructuredData } = useStructuredData();
        const isImageViewerShown = ref(false);
        const showMap = ref(false);
        const modalStatus = ref(false);
        const onMapShow = () => {
            showMap.value = !showMap.value;
            if (showMap.value) {
                root.$scrollTo('#map');
            }
        };
        const { refetch, onResult } = useGetVenueQuery(getVenueQueryVariables, getVenueQueryOptions);
        onResult((result) => {
            venue.value = result?.data?.specific_instance_by_slug;
            if (venue.value) {
                useMeta({
                    title: root.$i18n
                        .t('meta.venue.title_tag', { venue_name: venue.value.venue_name })
                        .toString(),
                    description: root.$i18n
                        .t('meta.venue.description_tag', {
                        venue_name: venue.value.venue_name,
                        city_name: venue.value.venue_geo.city.seo_entity_name
                    })
                        .toString(),
                    keywords: root.$i18n
                        .t('meta.general.meta_keywords', {
                        returnObjects: true
                    })
                        .map((keyword) => `${keyword} ${venue.value.venue_name} ${venue.value.venue_geo.city.seo_entity_name}`)
                        .join(','),
                    imageUrl: `${venue.value.venue_images[0]?.image_url}/tr:w-300,h-230` || '',
                    url: `${SITE_URL}/customer/venue${venue.value.venue_url}`,
                    noIndex: false
                });
                injectData(venueSchemaOrgInfo(venue.value));
            }
        });
        const schemaVenueInfo = computed(() => {
            return {
                '@context': 'https://schema.org',
                '@graph': []
            };
        });
        EventBus.$on('onBreadcrumbsReady', data => {
            injectData(data);
        });
        const injectData = (structuredData) => {
            const data = schemaVenueInfo.value;
            data['@graph'].push(structuredData);
            injectStructuredData({
                ...data
            });
        };
        watch(() => root.$route.params, (newParams, oldParams) => {
            if (newParams && newParams !== oldParams) {
                const { hash, name } = root.$route.params;
                getVenueQueryVariables.slug = `/${hash}/${name}`;
                getVenueQueryOptions.enabled = true;
                refetch(getVenueQueryVariables);
            }
        }, { immediate: true });
        const venueFeatures = computed(() => {
            const _features = venue.value?.venue_features || [];
            // If venue allows customer catering, add own_catering_allowed feature to the venue features list
            if (venue.value.allow_customer_catering) {
                _features.push({
                    feature_name: 'own_catering_allowed',
                    feature_type: 'VENUE',
                    feature_id: 0
                });
            }
            return _features;
        });
        watch(() => venue.value, (newValue, oldValue) => {
            if (venue.value &&
                newValue?.venue_geo.city.seo_entity_name !==
                    oldValue?.venue_geo.city.seo_entity_name) {
                // Send event
                globalThis.$gtm.trackEvent({
                    event: 'set_city',
                    category: 'geo',
                    action: 'venue_page_opened',
                    label: venue.value.venue_geo.city.seo_entity_name,
                    value: venue.value.venue_geo.city.seo_entity_name,
                    noninteraction: true
                });
                // Emit venue object to the VenueHeader.vue component for the breadcrumbs rendering
                EventBus.$emit('venueLoaded', venue.value);
            }
        }, { immediate: true });
        const staticServices = computed(() => {
            const services = venue?.value?.venue_services;
            const facilities = services.filter(el => el?.system_service_type === SystemServiceType.Facility);
            const catering = services.filter(el => el?.system_service_type === SystemServiceType.Catering);
            return { facilities, catering };
        });
        const selectedImageIndex = ref(0);
        const onShowImages = (imageIndex) => {
            selectedImageIndex.value = imageIndex;
            isImageViewerShown.value = true;
        };
        const onVenueTagsVisible = (isVisible) => {
            if (isVisible) {
                venueTagsVisible.value = true;
            }
        };
        const venueImages = computed(() => {
            if (!venue.value?.venue_images)
                return [];
            return venue.value.venue_images.filter(Boolean).map((image) => {
                return {
                    image_url: image.image_url
                };
            });
        });
        onUnmounted(() => {
            ejectStructuredData();
        });
        return {
            isImageViewerShown,
            modalStatus,
            selectedImageIndex,
            showMap,
            staticServices,
            venue,
            venueFeatures,
            venueImages,
            venueTagsVisible,
            onMapShow,
            onShowImages,
            onVenueTagsVisible
        };
    }
});
