import { defineComponent, computed } from '@vue/composition-api';
import ListWithMore from '@/Space/components/ListWithMore.vue';
export default defineComponent({
    name: 'SpaceFeatures',
    components: {
        ListWithMore
    },
    props: {
        space: {
            type: Object,
            required: true
        }
    },
    setup(props) {
        const spaceFeatures = computed(() => {
            return props.space.space_features.filter(Boolean);
        });
        return {
            spaceFeatures
        };
    }
});
