import OrganisationRating from '@/components/OrganisationRating.vue';
import SocialSharing from '@/Space/components/SocialSharing.vue';
import SectionSpaces from '@/Venue/components/SectionSpaces.vue';
import VenueOpeningHours from '@/Venue/components/VenueOpeningHours.vue';
import SectionHero from '@/components/SectionHero.vue';
import ImageViewer from '@/components/ImageViewer.vue';
import { Portal } from 'portal-vue';
import { defineComponent, computed, ref } from '@vue/composition-api';
import { useGetLocalizedPath } from '@/util/globalHelpers';
import { VenueRoutes } from '@/Venue/venue.const';
import { formatVenueOpeningHours } from '@/util/utils';
export default defineComponent({
    components: {
        ImageViewer,
        OrganisationRating,
        Portal,
        SectionHero,
        SectionSpaces,
        SocialSharing,
        VenueOpeningHours
    },
    props: {
        venue: {
            type: Object,
            required: true
        },
        isMapHidden: {
            type: Boolean,
            default: false
        }
    },
    setup(props) {
        const showSharingModal = ref(false);
        const selectedImageIndex = ref(0);
        const isImageViewerShown = ref(false);
        const spaces = computed(() => {
            if (!props.venue.spaces)
                return [];
            const result = props.venue.spaces.filter(Boolean).map((space) => space && {
                ...space,
                organisationRating: props.venue.organisationRating,
                venueName: props.venue.venueName,
                venueId: props.venue.venueId,
                venueUrl: props.venue.venueUrl,
                cityName: props.venue.city
            });
            return result || [];
        });
        const images = computed(() => {
            if (!props.venue.images)
                return [];
            return props.venue.images.filter(Boolean).map((image) => {
                return {
                    image_url: image
                };
            });
        });
        const venueOpeningHours = computed(() => {
            if (!props.venue.workingTime)
                return;
            return formatVenueOpeningHours(props.venue.workingTime);
        });
        return {
            images,
            isImageViewerShown,
            selectedImageIndex,
            showSharingModal,
            spaces,
            venueOpeningHours,
            VenueRoutes,
            useGetLocalizedPath
        };
    }
});
