import { render, staticRenderFns } from "./VenueOpeningHours.vue?vue&type=template&id=1333fc23&scoped=true"
import script from "./VenueOpeningHours.vue?vue&type=script&lang=ts"
export * from "./VenueOpeningHours.vue?vue&type=script&lang=ts"
import style0 from "./VenueOpeningHours.vue?vue&type=style&index=0&id=1333fc23&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1333fc23",
  null
  
)

export default component.exports