import { defineComponent, ref } from '@vue/composition-api';
export default defineComponent({
    name: 'SpaceActivitiesAccordion',
    props: {
        activities: {
            type: Array,
            default: () => []
        }
    },
    setup() {
        const collapsed = ref(true);
        const toggle = (event) => {
            event.preventDefault();
            collapsed.value = !collapsed.value;
        };
        return {
            collapsed,
            toggle
        };
    }
});
