import { defineComponent, computed, toRefs } from '@vue/composition-api';
import ListWithMore from '@/Space/components/ListWithMore.vue';
import { OrderItemType } from '@/generated-types/graphql.types';
export default defineComponent({
    name: 'SpaceConfigurations',
    components: {
        ListWithMore
    },
    props: {
        bookingSummary: {
            type: Object,
            required: true
        },
        space: {
            type: Object,
            required: true
        }
    },
    setup(props, context) {
        const { emit } = context;
        const { bookingSummary } = toRefs(props);
        const spaceConfigurations = computed(() => {
            return props.space.space_configurations.filter(Boolean);
        });
        const selectedConfigurationId = computed(() => {
            const selectedConfiguration = bookingSummary.value.order.order_items.find((orderItem) => orderItem.order_item_type === OrderItemType.Configuration);
            return selectedConfiguration ? selectedConfiguration.reference_id : null;
        });
        const onConfigurationUpdate = (configuration) => {
            emit('configurationUpdated', configuration);
        };
        return {
            selectedConfigurationId,
            spaceConfigurations,
            onConfigurationUpdate
        };
    }
});
