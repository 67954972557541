import { defineComponent, watch, computed, reactive, toRefs } from '@vue/composition-api';
import ListWithMore from '@/Space/components/ListWithMore.vue';
import { AvailableSpaceServices, AvailableVenueServices, StaticVenueServices } from '@/Space/space.state';
import { OrderItemType, SystemServiceType } from '@/generated-types/graphql.types';
export default defineComponent({
    name: 'SpaceFacilities',
    components: {
        ListWithMore
    },
    props: {
        bookingSummary: {
            type: Object,
            required: true
        },
        removeItemCallback: {
            type: Function,
            required: true
        },
        selectedSlots: {
            type: Boolean,
            required: true
        },
        space: {
            type: Object,
            required: true
        },
        spaceServices: {
            type: Array,
            default: () => []
        },
        updateItemCallback: {
            type: Function,
            required: true
        }
    },
    setup(props) {
        const availableSpaceServices = new AvailableSpaceServices().services;
        const spaceFacilities = computed(() => (props.space.space_services.filter(Boolean) || []));
        const availableFacilities = computed(() => {
            return (props.spaceServices
                .filter(Boolean)
                .map((service) => {
                const venueService = service.venue_service;
                if (venueService) {
                    venueService.remaining_inventory = service.remaining_inventory;
                }
                return venueService;
            }) || []);
        });
        const { bookingSummary } = toRefs(props);
        const staticServices = computed(() => {
            const venueServices = (props.space.venue.venue_services?.filter(Boolean) || []);
            return {
                facilities: venueServices.filter((service) => service.system_service_type === SystemServiceType.Facility),
                catering: venueServices.filter((service) => service.system_service_type === SystemServiceType.Catering)
            };
        });
        const staticVenueServices = new StaticVenueServices().services;
        const availableVenueServices = new AvailableVenueServices().services;
        const availability = reactive({
            errors: []
        });
        /**
         * @description
         * Restore all services that were selected by the user.
         * There are 2 places where selected services can be stored:
         * - localStorage in case when user returns to update the booking; services are unpacked into the bookingSummary
         *   (see bookingSummaryClass.getCachedSummary);
         * - URL query in case when user opens the space page with saved or shared link;
         */
        const restoreSelectedServices = () => {
            const selectedServices = bookingSummary.value.order.order_items.filter((item) => item.order_item_type === OrderItemType.Service);
            [
                ...availableVenueServices.facilities,
                ...availableVenueServices.catering,
                ...availableSpaceServices.default,
                ...availableSpaceServices.onDemand
            ].forEach((service) => {
                const selectedService = selectedServices.find((item) => item.reference_id === service.system_service_id);
                service.selected = selectedService ? selectedService.quantity : 0;
            });
            return;
        };
        /**
         * Sets initial values for available default space services
         */
        watch(() => spaceFacilities.value, () => {
            availableSpaceServices.default = spaceFacilities.value.filter((service) => !service.is_on_demand);
            availableSpaceServices.onDemand = spaceFacilities.value.filter((service) => service.is_on_demand);
            availableVenueServices.facilities = [
                ...staticServices.value.facilities
            ];
            availableVenueServices.catering = [...staticServices.value.catering];
        }, { immediate: true });
        watch(() => props.spaceServices, () => {
            if (!props.spaceServices.length)
                return;
            availableSpaceServices.onDemand = spaceFacilities.value
                .filter((service) => availableFacilities.value.find((availableService) => availableService.system_service_id ===
                service.system_service_id && availableService.is_on_demand))
                .map((service) => availableFacilities.value.find((availableService) => availableService.system_service_id === service.system_service_id))
                .filter(Boolean);
            availableVenueServices.facilities = staticVenueServices.facilities
                .filter((service) => availableFacilities.value.find((availableService) => availableService.system_service_id === service.system_service_id))
                .map((service) => availableFacilities.value.find((availableService) => availableService.system_service_id === service.system_service_id))
                .filter(Boolean);
            availableVenueServices.catering = staticVenueServices.catering
                .filter((service) => availableFacilities.value.find((availableService) => availableService.system_service_id === service.system_service_id))
                .map((service) => availableFacilities.value.find((availableService) => availableService.system_service_id === service.system_service_id))
                .filter(Boolean);
            availableVenueServices.updated = true;
            restoreSelectedServices();
        }, { immediate: true });
        watch(() => staticServices.value, () => {
            if (!staticServices.value)
                return;
            staticVenueServices.facilities = staticServices.value.facilities;
            staticVenueServices.catering = staticServices.value.catering;
        }, { immediate: true });
        const mandatoryServiceIds = computed(() => {
            return [
                ...availableVenueServices.catering,
                ...availableVenueServices.facilities,
                ...availableSpaceServices.onDemand
            ]
                .filter(service => service && service.is_mandatory)
                .map(service => service && service.system_service_id)
                .sort((a, b) => a - b);
        });
        const checkMandatory = () => {
            if (!bookingSummary.value.order.order_items.length) {
                return [];
            }
            const bookedServiceIds = bookingSummary.value.order.order_items
                .filter((item) => item.order_item_type === OrderItemType.Service)
                .map((item) => item.reference_id)
                .sort((a, b) => a - b);
            const missingMandatoryIds = mandatoryServiceIds.value.filter((id) => !bookedServiceIds.includes(id));
            if (missingMandatoryIds.length) {
                return missingMandatoryIds;
            }
            return [];
        };
        return {
            availability,
            availableSpaceServices,
            availableVenueServices,
            staticServices,
            checkMandatory
        };
    }
});
