import { computed, defineComponent, onBeforeUnmount } from '@vue/composition-api';
import NotificationsVenue from '@/Notifications/components/NotificationsVenue.vue';
import Access from '@/components/Access.vue';
import { useClearNotificationsMutation, UserRoleState } from '@/generated-types/graphql.types';
import VendorNotificationsProvider from '@/Notifications/components/VendorNotificationsProvider';
import CustomerNotificationsProvider from '@/Notifications/components/CustomerNotificationsProvider.vue';
import NotificationEventsList from '@/Notifications/NotificationEventsList.vue';
import { useGetLocalizedPath } from '@/util/globalHelpers';
export default defineComponent({
    components: {
        NotificationsVenue,
        VendorNotificationsProvider,
        CustomerNotificationsProvider,
        NotificationEventsList,
        Access
    },
    setup(_, context) {
        const { root } = context;
        const venueId = computed({
            get() {
                return +root.$route.params.venueId;
            },
            set(venueId) {
                if (+root.$route.params.venueId === venueId) {
                    return;
                }
                // Add venue_id to localStorage to keep it for space creation action card redirect
                // It is used to bind the space to the correct venue
                localStorage.setItem('venue_id', JSON.stringify(venueId));
                globalThis.$router.push({ params: { venueId } });
            }
        });
        const { mutate: clearAllNotifications } = useClearNotificationsMutation();
        const clearNotifications = (venueId) => {
            clearAllNotifications({ venueId });
        };
        /**
         * Clear localStorage used in venueId computable setter
         */
        onBeforeUnmount(() => {
            localStorage.removeItem('venue_id');
        });
        return {
            venueId,
            UserRoleState,
            clearNotifications,
            useGetLocalizedPath
        };
    }
});
