import { dateString, generateTimestamp, isDST } from '@/util/dates';
import { computed, defineComponent, onMounted, onUnmounted, reactive, ref, watch } from '@vue/composition-api';
import EventBus from '@/shared/services/eventBus';
import SectionHero from '@/components/SectionHero.vue';
import AddressBar from '@/components/AddressBar.vue';
import DetailInfo from '@/components/DetailInfo.vue';
import SectionNearby from '@/components/SectionNearby.vue';
import ImageViewer from '@/components/ImageViewer.vue';
import SectionLocation from '@/components/SectionLocation.vue';
import BookingSummary from '@/Booking/components/BookingSummary.vue';
import TagsList from '@/Tag/TagsList.vue';
import { withAuthMutationHook } from '@/util/hooks';
import { getItemLocalStorage } from '@/util/utils';
import SpaceActivitiesAccordion from '@/Space/components/SpaceActivitiesAccordion.vue';
import SectionDateTime from '@/Space/components/SectionDateTime.vue';
import MobileBookingFooter from '@/Space/components/MobileBookingFooter.vue';
import SocialDiscount from '@/Space/components/SocialDiscount.vue';
import UnpaidReservationModal from '@/Space/components/UnpaidReservationModal.vue';
import SpaceFacilities from '@/Space/components/SpaceFacilities.vue';
import SpaceFeatures from '@/Space/components/SpaceFeatures.vue';
import SpaceConfigurations from '@/Space/components/SpaceConfigurations.vue';
import PreSaveActionModal from '@/shared/components/PreSaveActionModal.vue';
import BookingConfirmation from '@/components/BookingConfirmationModal.vue';
import OrganisationRating from '@/components/OrganisationRating.vue';
import { OrderItemType, SystemServiceType, useCreateBookingMutation, useGetSpaceQuery, UserRoleState, useSpaceServicesAvailabilityQuery, useGetBookingQuery, SpaceBookingType, ChatMessageType, useUpdateSpaceSeoDetailsMutation, useGetSpaceSeoQuery } from '@/generated-types/graphql.types';
import { spaceSchemaOrgInfo } from '@/Space/space.seo';
import SpaceInfo from '@/Space/components/SpaceInfo.vue';
import { useUserDetailsCompleted } from '@/shared/composables/useUserDetailsCompleted';
import { ServerErrors } from '@/util/graphql';
import Coupon from '@/Coupon/Coupon.vue';
import { PRE_SAVE_ACTION_TYPES, preSaveAction, resetPreSaveAction } from '@/util/preSaveActions';
import { useGetLocalizedPath } from '@/util/globalHelpers';
import { useBookingCalculations, useCopyLink, useUpdateQueryParams, useSpaceChat } from '@/Space/space.hooks';
import VenueRules from '@/pages/customer/VenueRules.vue';
import { ADD_TOAST_MESSAGE } from 'vuex-toast';
import IncrementInput from '@/Space/components/IncrementInput.vue';
import useStructuredData from '@/shared/composables/useStructuredData';
import SocialLocationModal from '@/components/SocialLocationModal.vue';
import { SpacePageState, SpacePageQueryParams, BookingSummaryState } from '@/Space/space.state';
import { BookingRoutes } from '@/Booking/booking.const';
import { useMeta } from '@/shared/composables/useMeta';
import { SITE_URL } from '@/App.vue';
import { SpaceRoutes } from '@/Space/space.const';
import PageFAQ from '@/components/PageFAQ.vue';
import SaveFooter from '@/pages/profile/components/SaveFooter.vue';
export default defineComponent({
    components: {
        SaveFooter,
        PageFAQ,
        OrganisationRating,
        IncrementInput,
        SpaceInfo,
        SectionHero,
        AddressBar,
        DetailInfo,
        ImageViewer,
        MobileBookingFooter,
        SectionDateTime,
        SectionLocation,
        SectionNearby,
        BookingSummary,
        SocialDiscount,
        SocialLocationModal,
        SpaceActivitiesAccordion,
        SpaceConfigurations,
        SpaceFacilities,
        SpaceFeatures,
        UnpaidReservationModal,
        PreSaveActionModal,
        Coupon,
        BookingConfirmation,
        TagsList,
        VenueRules
    },
    setup(_, context) {
        const { root } = context;
        const user = computed(() => globalThis.$store.getters['$_app/user']);
        const spaceFacilitiesComponent = ref(null);
        const space = ref(undefined);
        const spaceTagsVisible = ref(false);
        const { performAccountFlowAction } = useUserDetailsCompleted(root);
        const { injectStructuredData, ejectStructuredData } = useStructuredData();
        const showMap = ref(false);
        const slotsSelected = ref(false);
        const editable = ref(false);
        const originalCouponDiscount = ref(null);
        const originalCouponId = ref(null);
        const { calculationInput, executeCalculations, onCalculationsDone } = useBookingCalculations(context);
        const { updateQueryParams } = useUpdateQueryParams(context);
        const { copyLink } = useCopyLink(context);
        const { createChat } = useSpaceChat(context, performAccountFlowAction);
        const faqQuestions = ref([]);
        const extraSeo = ref([]);
        let spaceSeo = reactive({
            h1: '',
            h2: '',
            description: '',
            metaTitle: '',
            metaDescription: ''
        });
        const onMapShow = () => {
            showMap.value = !showMap.value;
            if (showMap.value) {
                root.$scrollTo('#map');
            }
        };
        const modalSocialStatus = ref(false);
        const onCountChanged = (val) => {
            bookingSummary.attendees_selected_count = val;
            updateQueryParams({
                bookingSummary,
                editable: editable.value,
                couponDiscount: originalCouponDiscount.value,
                couponId: originalCouponId.value,
                bookingOrigin: bookingOrigin.value,
                isRequestBookingUpdateButtonDisabled
            });
        };
        const state = new SpacePageState().state;
        const queryParamsClass = new SpacePageQueryParams();
        const queryParams = queryParamsClass.params;
        queryParamsClass.readQuery();
        const bookingSummaryClass = new BookingSummaryState();
        const bookingSummary = bookingSummaryClass.summary;
        const hasUnfinishedAction = ref(false);
        const unfinishedActionType = ref('');
        const spaceCategories = computed(() => {
            if (!space.value?.space_categories)
                return [];
            return space.value.space_categories.filter(Boolean);
        });
        const acceptDiscount = ref(false);
        const { hash, name, reference } = root.$route.params;
        const slug = `/${hash}/${name}/${reference}`;
        const discountApplied = ref(queryParams.isDiscountRequested);
        const coupon = ref(null);
        const forceApplyCoupon = ref(false);
        const isReservationsLimitReached = ref(false);
        const reservationsLimit = ref(5);
        const { onResult } = useGetSpaceQuery({ slug }, { fetchPolicy: 'no-cache' });
        onResult((result) => {
            space.value = result?.data?.specific_instance_by_slug;
            if (space.value) {
                space.value.space_services.map(el => el.remaining_inventory === el.is_on_demand ? 0 : undefined);
                // If the venue has own catering enabled, append it to the space features
                if (space.value.venue.allow_customer_catering) {
                    space.value.space_features.push({
                        feature_type: 'VENUE',
                        feature_name: 'own_catering_allowed',
                        feature_id: 0
                    });
                }
            }
            useMeta({
                keywords: root.$i18n
                    .t('meta.general.meta_keywords', {
                    returnObjects: true
                })
                    .map((keyword) => `${keyword} ${space.value.venue.venue_name} ${space.value.internal_name} ${space.value.venue.venue_geo.city.seo_entity_name}`)
                    .join(','),
                imageUrl: `${space.value.space_images[0]?.image_url}/tr:w-300,h-230` || '',
                url: `${SITE_URL}/${SpaceRoutes.SPACE}${space.value.space_url}`,
                // remove page from indexing if slots are set (== if any query params exist)
                noIndex: !!slots.value?.slotFrom
            });
            injectData(spaceSchemaOrgInfo(space.value));
        });
        const { onResult: onSeoResult, refetch: refetchSeoInfo } = useGetSpaceSeoQuery({ slug }, { fetchPolicy: 'no-cache' });
        onSeoResult((result) => {
            if (!result?.data?.specific_instance_by_slug)
                return;
            spaceSeo.h1 = result.data.specific_instance_by_slug.seo.h1;
            spaceSeo.h2 = result.data.specific_instance_by_slug.seo.h2 || '';
            spaceSeo.description =
                result.data.specific_instance_by_slug.seo.description || '';
            spaceSeo.meta_title =
                result.data.specific_instance_by_slug.seo.meta_title || '';
            spaceSeo.meta_description =
                result.data.specific_instance_by_slug.seo.meta_description || '';
            faqQuestions.value =
                result.data.specific_instance_by_slug.faqs.map((question) => {
                    return {
                        question: question.question,
                        answer: question.answer
                    };
                }) || [];
            extraSeo.value =
                result.data.specific_instance_by_slug.seo_extra.map((extra) => {
                    return {
                        title: extra.title,
                        description: extra.description
                    };
                }) || [];
            useMeta({
                title: spaceSeo.meta_title,
                description: spaceSeo.meta_description
            });
        });
        EventBus.$on('languageSelected', refetchSeoInfo);
        const schemaSpaceInfo = computed(() => {
            return {
                '@context': 'https://schema.org',
                '@graph': []
            };
        });
        EventBus.$on('onBreadcrumbsReady', data => {
            injectData(data);
        });
        const injectData = (structuredData) => {
            const data = schemaSpaceInfo.value;
            data['@graph'].push(structuredData);
            injectStructuredData({
                ...data
            });
        };
        /**
         * @description
         * First the slots from cache are loaded (case when user returned to update booking details).
         * If empty, the slots from the query params are loaded (case when user used a copied space page link).
         * Fallback is the current timestamp slots.
         */
        const slots = computed(() => {
            return {
                slotFrom: queryParams.slotStart || bookingSummary.slot_start || null,
                slotTo: queryParams.slotEnd || bookingSummary.slot_end || null
            };
        });
        const availability = reactive({
            errors: []
        });
        const servicesAvailabilityVariables = reactive({
            space_id: 0,
            booking_start: '',
            booking_end: '',
            booking_edit_mode: null
        });
        const servicesAvailabilityOptions = reactive({ enabled: false });
        const { result: services, refetch: refetchServices } = useSpaceServicesAvailabilityQuery(servicesAvailabilityVariables, servicesAvailabilityOptions);
        let bookingOrigin = ref(null);
        let isRequestBookingUpdateButtonDisabled = ref(false);
        onMounted(() => {
            originalCouponDiscount.value = queryParams.couponDiscount;
            originalCouponId.value = queryParams.couponId;
            editable.value = queryParams.editable;
            if (editable.value) {
                servicesAvailabilityVariables.booking_edit_mode = {
                    booking_id: +sessionStorage.getItem('bookingId')
                };
                const { onResult: getOriginBooking } = useGetBookingQuery({ id: +sessionStorage.getItem('bookingId') }, {
                    fetchPolicy: 'no-cache'
                });
                getOriginBooking((result) => {
                    if (!result?.data?.specific_booking)
                        return;
                    const booking = result.data.specific_booking;
                    const _isDST = isDST(booking.slot_start);
                    bookingOrigin.value = {
                        slotEnd: _isDST ? booking.slot_end + 3600 : booking.slot_end,
                        slotStart: _isDST ? booking.slot_start + 3600 : booking.slot_start,
                        attendees: booking.attendees,
                        isDiscountRequested: booking.is_discount_applied,
                        configuration: booking.order.order_items.find(service => service.order_item_type === OrderItemType.Configuration).reference_id,
                        'facilities[]': booking.order.order_items
                            .filter(item => {
                            return (item.order_item_type === OrderItemType.Service &&
                                item.service_type === SystemServiceType.Facility);
                        })
                            .map(item => `${item.service_name}+${item.reference_id}+${item.quantity}+${item.is_mandatory}+${item.reference_id}`),
                        'caterings[]': booking.order.order_items
                            .filter(item => {
                            return (item.order_item_type === OrderItemType.Service &&
                                item.service_type === SystemServiceType.Catering);
                        })
                            .map(item => `${item.service_name}+${item.reference_id}+${item.quantity}+${item.is_mandatory}+${item.reference_id}`),
                        editable: true,
                        couponDiscount: booking.booking_meta?.coupon?.discount_percentage,
                        couponId: booking.booking_meta?.coupon?.coupon_id
                    };
                    updateQueryParams({
                        bookingSummary,
                        editable: editable.value,
                        couponDiscount: originalCouponDiscount.value,
                        couponId: originalCouponId.value,
                        bookingOrigin: bookingOrigin.value,
                        isRequestBookingUpdateButtonDisabled
                    });
                });
            }
            if (Object.keys(root.$route.query).length) {
                setTimeout(() => {
                    root.$scrollTo('#calendar', 1500, {
                        offset: -60
                    });
                }, 500);
            }
            EventBus.$on('updateBookingDay', day => {
                state.selectedDay = day;
            });
            EventBus.$on('updateBookingStartTime', time => {
                bookingSummary.slot_start = generateTimestamp(time, state.selectedDay, globalThis.$timezone);
            });
            EventBus.$on('updateBookingEndTime', time => {
                bookingSummary.slot_end = generateTimestamp(time, state.selectedDay, globalThis.$timezone);
            });
        });
        watch(() => bookingSummary.attendees_selected_count, (newValue, oldValue) => {
            if (oldValue === 0 && newValue !== 0) {
                state.error.text = '';
                state.error.type = '';
            }
        }, { immediate: true });
        watch(() => [bookingSummary.slot_start, bookingSummary.slot_end], (newValue, oldValue) => {
            const [newSlotStart, newSlotEnd] = newValue;
            let [oldSlotStart, oldSlotEnd] = [0, 0];
            if (Array.isArray(oldValue)) {
                [oldSlotStart, oldSlotEnd] = oldValue;
            }
            if (newSlotStart === oldSlotStart && newSlotEnd === oldSlotEnd)
                return;
            if (newSlotStart &&
                newSlotEnd &&
                space.value &&
                newSlotStart !== newSlotEnd) {
                state.error.text = '';
                state.error.type = '';
                const bookingStart = dateString(newSlotStart, globalThis.$timezone);
                const bookingEnd = dateString(newSlotEnd, globalThis.$timezone);
                slotsSelected.value = true;
                servicesAvailabilityOptions.enabled = true;
                servicesAvailabilityVariables.space_id = space.value.space_id;
                servicesAvailabilityVariables.booking_start = bookingStart;
                servicesAvailabilityVariables.booking_end = bookingEnd;
                refetchServices(servicesAvailabilityVariables);
                calculationInput.input.booking_start = bookingStart;
                calculationInput.input.booking_end = bookingEnd;
                executeCalculations({
                    isDiscountApplied: discountApplied.value,
                    couponId: coupon?.value?.coupon_id,
                    editable: editable.value,
                    bookingSummary,
                    couponDiscount: originalCouponDiscount.value,
                    bookingOrigin: bookingOrigin.value,
                    isRequestBookingUpdateButtonDisabled
                });
            }
        }, { immediate: true });
        watch(space, (newSpace, oldSpace) => {
            if (!newSpace) {
                return;
            }
            // Show unfinished action modal
            const preSavedType = localStorage.getItem('preSavedType');
            const preSavedSpace = getItemLocalStorage('preSavedSpace');
            if (preSavedType &&
                (preSavedType === PRE_SAVE_ACTION_TYPES.BOOKING ||
                    preSavedType === PRE_SAVE_ACTION_TYPES.CHAT ||
                    preSavedType === PRE_SAVE_ACTION_TYPES.COUPON_CODE) &&
                preSavedSpace &&
                preSavedSpace.space_id === newSpace.space_id) {
                hasUnfinishedAction.value = true;
                unfinishedActionType.value = preSavedType;
            }
            // Emit space object to the SpaceHeader.vue component for the breadcrumbs rendering
            EventBus.$emit('spaceLoaded', space.value);
            if (newSpace?.venue.venue_geo.city.seo_entity_name !==
                oldSpace?.venue.venue_geo.city.seo_entity_name) {
                // Send event
                globalThis.$gtm.trackEvent({
                    event: 'set_city',
                    category: 'geo',
                    action: 'space_page_opened',
                    label: newSpace.venue.venue_geo.city.seo_entity_name,
                    value: newSpace.venue.venue_geo.city.seo_entity_name,
                    noninteraction: true
                });
            }
            let selectedConfiguration = newSpace.space_configurations.find(configuration => queryParams.configuration
                ? configuration.configuration_id === queryParams.configuration
                : configuration?.is_default);
            if (selectedConfiguration.max_guests < queryParams.attendees) {
                selectedConfiguration =
                    newSpace.space_configurations
                        .sort((a, b) => a.max_guests - b.max_guests)
                        .find(configuration => configuration.max_guests >= queryParams.attendees) || selectedConfiguration;
            }
            const cachedBookingSummary = bookingSummaryClass.getCachedSummary(space.value.space_id, discountApplied);
            if (!cachedBookingSummary) {
                bookingSummary.is_discount_applied = discountApplied.value;
                bookingSummary.is_coupon_applied =
                    !!newSpace.coupon ||
                        (editable.value && originalCouponDiscount.value !== null);
                bookingSummary.discount_rate =
                    newSpace.coupon?.discount_percentage ||
                        (editable.value && originalCouponDiscount.value !== null
                            ? originalCouponDiscount.value
                            : null) ||
                        space.value.discount_percentage;
                newSpace.discount_percentage;
                bookingSummary.space = newSpace;
                bookingSummary.attendees_max = selectedConfiguration?.max_guests;
                bookingSummary.attendees_selected_count = queryParams.attendees || 0;
                bookingSummary.order.order_items.push({
                    reference_id: newSpace.space_id,
                    order_item_type: OrderItemType.Space,
                    quantity: 1,
                    price: null
                });
                bookingSummary.order.order_items.push({
                    reference_id: selectedConfiguration?.configuration_id,
                    order_item_type: OrderItemType.Configuration,
                    quantity: 1,
                    configuration_type: selectedConfiguration?.configuration_type,
                    price: null
                });
                // Load services from query (if stated)
                queryParams.caterings.forEach(item => {
                    bookingSummary.order.order_items.push({
                        reference_id: item.referenceId,
                        order_item_type: OrderItemType.Service,
                        quantity: item.quantity,
                        price: null,
                        is_mandatory: item.is_mandatory,
                        system_service_id: item.system_service_id,
                        name: item.service_name,
                        service_type: SystemServiceType.Catering
                    });
                });
                queryParams.facilities.forEach(item => {
                    bookingSummary.order.order_items.push({
                        reference_id: item.referenceId,
                        order_item_type: OrderItemType.Service,
                        quantity: item.quantity,
                        price: null,
                        is_mandatory: item.is_mandatory,
                        system_service_id: item.system_service_id,
                        name: item.service_name,
                        service_type: SystemServiceType.Facility
                    });
                });
            }
            if (newSpace.coupon) {
                coupon.value = newSpace.coupon;
                discountApplied.value = false;
            }
            if (editable.value && originalCouponDiscount.value !== null) {
                coupon.value = {
                    coupon_id: originalCouponId.value,
                    discount_percentage: originalCouponDiscount.value
                };
                discountApplied.value = false;
            }
        }, { immediate: true });
        // mutation to create a booking
        const { mutate } = withAuthMutationHook(useCreateBookingMutation)({
            clientId: 'legacy'
        });
        onCalculationsDone(({ data }) => {
            const calculations = data?.get_booking_preliminary_calculation;
            if (calculations) {
                isReservationsLimitReached.value =
                    calculations.is_reservations_limit_reached;
                reservationsLimit.value = calculations.reservations_limit;
                bookingSummary.order.discount_total = calculations.discount;
                bookingSummary.order.order_total_vat_excl = calculations.total_vat_excl;
                bookingSummary.order.order_total = calculations.total_vat_incl;
                const spaceOrderItem = bookingSummary.order.order_items.find(item => item.order_item_type === OrderItemType.Space);
                if (spaceOrderItem) {
                    spaceOrderItem.price =
                        calculations.space_price_with_service_fee_vat_excl;
                }
                const configurationOrderItem = bookingSummary.order.order_items.find(item => item.order_item_type === OrderItemType.Configuration);
                if (configurationOrderItem) {
                    configurationOrderItem.price =
                        calculations.configuration_price_with_service_fee_vat_excl;
                }
                bookingSummary.total_vat_excl = calculations.total_vat_excl;
                bookingSummary.total = calculations.total_vat_incl;
                bookingSummary.vat_21 = calculations.vat_21;
                bookingSummary.vat_9 = calculations.vat_9;
                calculations.order_items?.forEach(calculatedOrderItem => {
                    const orderItem = bookingSummary.order.order_items.find(item => item.order_item_type === OrderItemType.Service &&
                        item.reference_id === calculatedOrderItem.reference_id);
                    if (orderItem) {
                        orderItem.price =
                            calculatedOrderItem.order_item_price_with_service_fee_vat_excl;
                    }
                });
            }
        });
        const updateConfiguration = configuration => {
            bookingSummary.attendees_max = configuration.max_guests;
            if (bookingSummary.attendees_max < bookingSummary.attendees_selected_count) {
                bookingSummary.attendees_selected_count = bookingSummary.attendees_max;
                root.$scrollTo('#guests-amount', 1000, {
                    offset: -100
                });
                const addToast = globalThis.$store.dispatch.bind(null, ADD_TOAST_MESSAGE);
                addToast({
                    text: root.$i18n.t('customer.space.attendees.toast_message'),
                    type: 'danger',
                    dismissAfter: 8000
                });
            }
            const bookingSummaryConfiguration = bookingSummary.order.order_items.find(item => item.order_item_type === OrderItemType.Configuration);
            if (bookingSummaryConfiguration) {
                bookingSummaryConfiguration.configuration_type =
                    configuration.configuration_type;
                bookingSummaryConfiguration.reference_id =
                    configuration.configuration_id;
            }
            executeCalculations({
                isDiscountApplied: discountApplied.value,
                couponId: coupon?.value?.coupon_id,
                editable: editable.value,
                bookingSummary,
                couponDiscount: originalCouponDiscount.value,
                bookingOrigin: bookingOrigin.value,
                isRequestBookingUpdateButtonDisabled
            });
        };
        const checkAvailability = () => {
            const bookingServices = bookingSummary.order.order_items.filter(item => item?.order_item_type === OrderItemType.Service);
            let availableServices = [];
            servicesAvailabilityOptions.enabled = true;
            servicesAvailabilityVariables.space_id = space.value.space_id;
            servicesAvailabilityVariables.booking_start = dateString(bookingSummary.slot_start, globalThis.$timezone);
            servicesAvailabilityVariables.booking_end = dateString(bookingSummary.slot_end, globalThis.$timezone);
            refetchServices(servicesAvailabilityVariables)?.then(res => {
                availableServices = res?.data?.space_service_balance;
                if (availableServices) {
                    bookingServices.forEach(s => {
                        let found = availableServices.find(service => 
                        // service.venue_service.expect_quantity &&
                        service.venue_service.system_service_id === s.reference_id &&
                            service.remaining_inventory < s.quantity);
                        if (found) {
                            availability.errors.push(found.venue_service.system_service_id);
                            const index = bookingSummary.order.order_items.findIndex(el => el?.order_item_type === OrderItemType.Service &&
                                el.reference_id === found.venue_service.system_service_id);
                            if (index > -1)
                                bookingSummary.order.order_items.splice(index, 1);
                        }
                    });
                    if (availability.errors[0]) {
                        root.$scrollTo(`#service-${availability.errors[0]}`, 1500, {
                            offset: -100
                        });
                    }
                }
            });
        };
        const makeBooking = (space, force = false, bookingIdToCancel) => {
            resetPreSaveAction();
            return () => {
                const data = {
                    bookingIdToCancel: bookingIdToCancel,
                    forceCancel: force,
                    attendees: bookingSummary.attendees_selected_count,
                    bookingEnd: dateString(bookingSummary.slot_end, globalThis.$timezone),
                    bookingStart: dateString(bookingSummary.slot_start, globalThis.$timezone),
                    isPendingForDiscount: bookingSummary.is_discount_applied,
                    spaceId: space.space_id,
                    couponId: coupon?.value?.coupon_id,
                    orderItems: bookingSummary.order.order_items.map(item => {
                        return {
                            orderItemType: item.order_item_type,
                            quantity: item.quantity,
                            referenceId: item.reference_id
                        };
                    }),
                    bookingEditMode: editable.value
                        ? {
                            bookingId: +sessionStorage.getItem('bookingId')
                        }
                        : null
                };
                mutate({ input: data }, {})
                    .then(res => {
                    localStorage.setItem('cachedBookingSummary', JSON.stringify(bookingSummary));
                    globalThis.$router.push(useGetLocalizedPath(`${BookingRoutes.BOOKING}/${res?.data?.mutationViewerApiKey?.postApiBookingSpace?.bookingId}`));
                })
                    .catch(({ graphQLErrors }) => {
                    if (graphQLErrors) {
                        const error = ServerErrors.extractGQLError(graphQLErrors);
                        state.error.text = `common.errors.${error?.error.key}`;
                        state.error.type = 'graphQLErrors';
                        root.$scrollTo('#calendar', 1500, {
                            offset: -60
                        });
                        if (error.error.key === 'booking.inventory_limits_violated') {
                            checkAvailability();
                        }
                    }
                });
            };
        };
        const checkRequiredBookingFields = (event, force = false) => {
            if (force)
                state.showUnpaidReservationModal = false;
            if (bookingSummary.slot_end === bookingSummary.slot_start) {
                state.error.text = 'customer.space.book_preview.booking_slots_error';
                state.error.type = 'calendar';
                root.$scrollTo('#calendar', 1500, {
                    offset: -60
                });
                return;
            }
            if (bookingSummary.attendees_selected_count === 0) {
                state.error.text = 'customer.space.attendees.error_text';
                state.error.type = 'attendees';
                root.$scrollTo('#guests-amount', 1000, {
                    offset: -100
                });
                return;
            }
            if (spaceFacilitiesComponent.value) {
                const requiredIds = spaceFacilitiesComponent.value.checkMandatory();
                if (requiredIds.length) {
                    state.error.text =
                        'common.errors.booking.mandatory_services_violated';
                    state.error.type = 'is_mandatory';
                    root.$scrollTo(`#service-${requiredIds[0]}`, 1500, {
                        offset: -100
                    });
                    return;
                }
                else {
                    state.error.text = '';
                    state.error.type = '';
                }
            }
            // show unpaid reservations modal if user has reached the max reservations limit
            if (isReservationsLimitReached.value && !force) {
                state.showUnpaidReservationModal = true;
                return;
            }
            if (space.value.space_booking_type === SpaceBookingType.Direct &&
                !discountApplied.value) {
                handleSubmit(event, force);
            }
            else {
                state.showBookingConfirmationModal = true;
            }
        };
        const handleSubmit = (event, force = false) => {
            let bookingIdToCancel;
            if (typeof event === 'number') {
                bookingIdToCancel = event;
            }
            if (performAccountFlowAction.value) {
                preSaveAction({
                    type: PRE_SAVE_ACTION_TYPES.BOOKING,
                    space: space,
                    summary: bookingSummary
                });
                performAccountFlowAction.value(UserRoleState.Booker);
                return;
            }
            makeBooking(space.value, force, bookingIdToCancel)();
        };
        const continuePreSaveAction = () => {
            if (performAccountFlowAction.value) {
                performAccountFlowAction.value(UserRoleState.Booker);
                return;
            }
            let preSavedType = localStorage.getItem('preSavedType');
            let preSavedSpace = getItemLocalStorage('preSavedSpace');
            let preSavedBookingSummary = getItemLocalStorage('preSavedBookingSummary');
            let preSavedChatType = localStorage.getItem('preSavedChatType');
            if (preSavedBookingSummary) {
                Object.assign(bookingSummary, preSavedBookingSummary);
            }
            if (preSavedType === PRE_SAVE_ACTION_TYPES.BOOKING) {
                makeBooking(preSavedSpace)();
            }
            else if (preSavedType === PRE_SAVE_ACTION_TYPES.CHAT) {
                createChat({ space: preSavedSpace, chatMessageType: preSavedChatType });
            }
            else if (preSavedType === PRE_SAVE_ACTION_TYPES.COUPON_CODE) {
                forceApplyCoupon.value = true;
            }
        };
        const cancelPreSaveSpaceAction = () => {
            hasUnfinishedAction.value = false;
            unfinishedActionType.value = '';
            resetPreSaveAction();
        };
        const onUpdateEvent = (_, item, count) => {
            if (availability.errors.includes(item.system_service_id)) {
                const index = availability.errors.findIndex(el => el === item.system_service_id);
                availability.errors.splice(index, 1);
            }
            const orderItem = {
                order_item_type: OrderItemType.Service,
                quantity: count,
                reference_id: item.system_service_id
            };
            bookingSummary.order.order_items.push({
                ...orderItem,
                price: null,
                is_mandatory: item.is_mandatory,
                name: item.system_service_name,
                system_service_id: item.system_service_id,
                service_type: item.system_service_type
            });
            executeCalculations({
                isDiscountApplied: discountApplied.value,
                couponId: coupon?.value?.coupon_id,
                editable: editable.value,
                bookingSummary,
                couponDiscount: originalCouponDiscount.value,
                bookingOrigin: bookingOrigin.value,
                isRequestBookingUpdateButtonDisabled
            });
        };
        const onRemoveEvent = (_, item) => {
            if (availability.errors.includes(item?.system_service_id)) {
                availability.errors = [];
            }
            // remove service from booking summary
            bookingSummary.order.order_items =
                bookingSummary.order.order_items.filter(_item => _item.reference_id !== item.system_service_id);
            executeCalculations({
                isDiscountApplied: discountApplied.value,
                couponId: coupon?.value?.coupon_id,
                editable: editable.value,
                bookingSummary,
                couponDiscount: originalCouponDiscount.value,
                bookingOrigin: bookingOrigin.value,
                isRequestBookingUpdateButtonDisabled
            });
        };
        const discountRequested = () => {
            if (!discountApplied.value) {
                // If user applies for a discount
                acceptDiscount.value = true;
                root.$scrollTo('#discount');
            }
            else {
                discountSelected();
            }
        };
        const discountSelected = () => {
            if (!bookingSummary.slot_start || !bookingSummary.slot_end) {
                // slots must be selected to request a discount
                state.error.text = globalThis.$i18n
                    .t('customer.space.book_preview.booking_slots_error')
                    .toString();
                state.error.type = 'calendar';
                root.$scrollTo('#calendar', 1500, {
                    offset: -60
                });
                return;
            }
            // Toggle discount applied boolean
            discountApplied.value = !discountApplied.value;
            acceptDiscount.value = false;
            bookingSummary.is_discount_applied = discountApplied.value;
            bookingSummary.discount_rate = space.value.discount_percentage;
            executeCalculations({
                isDiscountApplied: discountApplied.value,
                couponId: coupon?.value?.coupon_id,
                editable: editable.value,
                bookingSummary,
                couponDiscount: originalCouponDiscount.value,
                bookingOrigin: bookingOrigin.value,
                isRequestBookingUpdateButtonDisabled
            });
        };
        const removeService = item => {
            EventBus.$emit('removeService', item);
            onRemoveEvent(item.service_type.toLowerCase(), {
                ...item,
                system_service_id: item.reference_id
            });
        };
        const selectedImageIndex = ref(0);
        const spaceImages = computed(() => {
            if (!space.value?.space_images)
                return [];
            return space.value.space_images.filter(Boolean).map((image) => {
                return {
                    image_url: image.image_url
                };
            });
        });
        const onShowImages = imageIndex => {
            selectedImageIndex.value = imageIndex;
            state.isShowImageViewer = true;
        };
        const handleCouponAction = (appliedCoupon) => {
            coupon.value = appliedCoupon ? appliedCoupon : null;
            bookingSummary.is_coupon_applied = appliedCoupon ? true : null;
            bookingSummary.discount_rate = coupon?.value?.discount_percentage || null;
            if (calculationInput.input.booking_start)
                executeCalculations({
                    isDiscountApplied: discountApplied.value,
                    couponId: coupon?.value?.coupon_id,
                    editable: editable.value,
                    bookingSummary,
                    couponDiscount: originalCouponDiscount.value,
                    bookingOrigin: bookingOrigin.value,
                    isRequestBookingUpdateButtonDisabled
                });
        };
        const backToOriginal = async () => {
            globalThis.$router.back();
        };
        const onSpaceTagsVisible = (isVisible) => {
            if (isVisible) {
                spaceTagsVisible.value = true;
            }
        };
        const seoUpdatesInProgress = ref(false);
        const isSpaceInfoUpdated = ref(false);
        const seoSpaceH1 = ref(null);
        const seoSpaceH2 = ref('');
        const seoSpaceDescription = ref('');
        const { mutate: changeSeoInfo } = useUpdateSpaceSeoDetailsMutation();
        const onRevertChanges = () => {
            seoSpaceH1.value.innerHTML = spaceSeo.h1;
            EventBus.$emit('revertAdminUpdates');
            seoUpdatesInProgress.value = false;
        };
        const handleAdminUpdates = (element, updates) => {
            if (element === 'seoSpaceH2') {
                seoSpaceH2.value = updates;
            }
            else {
                seoSpaceDescription.value = updates;
            }
            handleAdminInput();
        };
        const handleAdminInput = () => {
            seoUpdatesInProgress.value = true;
        };
        /**
         * Handles partial rich text formatting (bold and italic text)
         * @param event
         */
        const handleKeyDown = (event) => {
            if (event.ctrlKey || event.metaKey) {
                switch (event.key.toLowerCase()) {
                    case 'b':
                        event.preventDefault();
                        document.execCommand('bold');
                        break;
                    case 'i':
                        event.preventDefault();
                        document.execCommand('italic');
                        break;
                }
            }
        };
        const hasChanges = () => {
            return seoUpdatesInProgress.value;
        };
        const onClose = () => {
            isSpaceInfoUpdated.value = false;
        };
        const onSaveChanges = () => {
            if (space.value) {
                const input = {
                    space_id: space.value.space_id,
                    h1: seoSpaceH1.value.innerHTML,
                    h2: seoSpaceH2.value || spaceSeo.h2,
                    description: seoSpaceDescription.value || spaceSeo.description,
                    locale: globalThis.$i18n.locale.toUpperCase()
                };
                changeSeoInfo({ input })
                    .then(() => {
                    refetchSeoInfo();
                    seoUpdatesInProgress.value = false;
                    isSpaceInfoUpdated.value = true;
                })
                    .catch(({ graphQLErrors }) => {
                    if (graphQLErrors) {
                        console.log(graphQLErrors);
                    }
                });
            }
        };
        const isSaved = () => {
            return isSpaceInfoUpdated.value;
        };
        const scrollShadow = reactive({
            top: false,
            bottom: false
        });
        const onSummaryScroll = (e) => {
            scrollShadow.bottom =
                e.target.scrollHeight - e.target.scrollTop - e.target.offsetHeight > 1;
            scrollShadow.top = e.target.scrollTop !== 0;
        };
        onUnmounted(() => {
            ejectStructuredData();
        });
        return {
            acceptDiscount,
            availability,
            bookingSummary,
            cancellationTime: computed(() => space.value?.cancellation_th),
            coupon,
            discountApplied,
            editable,
            extraSeo,
            faqQuestions,
            forceApplyCoupon,
            hasUnfinishedAction,
            isRequestBookingUpdateButtonDisabled,
            modalSocialStatus,
            reservationsLimit,
            scrollShadow,
            selectedImageIndex,
            seoSpaceH1,
            seoSpaceH2,
            seoSpaceDescription,
            showMap,
            slots,
            slotsSelected,
            space,
            spaceCategories,
            spaceFacilitiesComponent,
            spaceImages,
            spaceSeo,
            spaceServices: computed(() => services.value?.space_service_balance || []),
            spaceTagsVisible,
            state,
            unfinishedActionType,
            user,
            ChatMessageType,
            SpaceBookingType,
            UserRoleState,
            backToOriginal,
            cancelPreSaveSpaceAction,
            checkRequiredBookingFields,
            continuePreSaveAction,
            copyLink,
            createChat,
            discountRequested,
            discountSelected,
            injectData,
            isSaved,
            hasChanges,
            handleAdminInput,
            handleAdminUpdates,
            handleCouponAction,
            handleKeyDown,
            handleSubmit,
            onClose,
            onCountChanged,
            onSaveChanges,
            onMapShow,
            onRemoveEvent,
            onRevertChanges,
            onShowImages,
            onSpaceTagsVisible,
            onSummaryScroll,
            onUpdateEvent,
            removeService,
            updateConfiguration
        };
    }
});
