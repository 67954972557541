import { computed, defineComponent, reactive, watch } from '@vue/composition-api';
import { EventNotificationName, useVendorNotificationsQuery } from '@/generated-types/graphql.types';
import groupBy from 'lodash/groupBy';
import remove from 'lodash/remove';
export default defineComponent({
    props: {
        venueId: {
            type: Number,
            default: null
        }
    },
    setup(props, context) {
        const { emit } = context;
        const queryVariables = reactive({
            venueId: props.venueId
        });
        const queryOptions = reactive({
            enabled: false,
            fetchPolicy: 'network-only'
        });
        const { result, onResult, loading, refetch } = useVendorNotificationsQuery(queryVariables, queryOptions);
        /**
         * Watching props venueId changes to run the useVendorNotificationsQuery
         */
        watch(() => props.venueId, () => {
            if (props.venueId) {
                queryVariables.venueId = props.venueId;
                queryOptions.enabled = true;
                refetch(queryVariables);
            }
        }, { immediate: true });
        const events = computed(() => {
            if (!result.value?.get_notifications)
                return [];
            return result.value.get_notifications.filter((notification) => notification && !notification.is_deleted);
        });
        const data = computed(() => {
            const notifications = groupBy(events.value, (event) => event?.event_type);
            const dayOverview = remove(notifications.NOTIFICATION, notification => notification?.event_name === EventNotificationName.DayOverview);
            return {
                ...notifications,
                DAY_OVERVIEW: dayOverview
            };
        });
        onResult(() => {
            emit('loaded', {
                notifications: data.value.NOTIFICATION,
                actions: data.value.ACTION
            });
        });
        return {
            data,
            loading
        };
    }
});
