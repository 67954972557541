import { isObject, isArray } from 'lodash';
import { enumDayOfWeek } from '@/util/dates';
/**
 * Debounces a function to enhance performance for events like scrolling, resizing, etc.
 * The function limits the rate at which a function can fire.
 *
 * @param {Function} func - The original function that you want to debounce.
 * @param {number} wait - Time in milliseconds to delay the function execution after the last event.
 * @param {boolean} immediate - If true, triggers the function on the leading edge instead of the trailing edge.
 * @returns {Function} A debounced version of the original function.
 */
export const debounce = (func, wait, immediate) => {
    let timeout;
    return function fn(...args) {
        const context = this;
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            timeout = null;
            if (!immediate)
                func.apply(context, args);
        }, wait);
        if (immediate && !timeout)
            func.apply(context, args);
    };
};
/**
 * Save current full path to local storage if it's not the homepage
 * User will be redirected to the saved URL after successful authentication action
 * @param {string} url - The current URL to be saved
 * @returns {void}
 */
export const saveCurrentPath = (url) => {
    localStorage.setItem('loginRedirectUrl', url);
};
/**
 * Format time from minutes into string HH:mm
 * @param {number} minutes Number of minutes (1350)
 * @returns {string} Time string (22:30)
 */
export const reFormatTime = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const minutesLeft = minutes % 60;
    return `${hours < 10 ? `0${hours}` : hours}:${minutesLeft < 10 ? `0${minutesLeft}` : minutesLeft}`;
};
/**
 * Parses a JSON Web Token (JWT) and returns its payload as a JSON object.
 * JWTs are typically composed of three parts separated by dots (.): Header, Payload, and Signature.
 * This function extracts and decodes the Payload part of the JWT.
 *
 * @param {string} st - The JWT string to be parsed.
 * @returns {Object} The decoded payload of the JWT as a JSON object.
 */
export const parseJwt = (st) => {
    const base64Url = st.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64)
        .split('')
        .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    })
        .join(''));
    return JSON.parse(jsonPayload);
};
/**
 * Get removed and added images for venues or spaces
 * @param {Array<Object>} oldArray Previous array of images
 * @param {Array<Object>} newArray New array of images
 * @returns {Object} Object with removed and added images
 */
export const checkRemovedAndNewImages = (oldArray, newArray) => {
    const oldIds = oldArray.map((image) => image.image_id || image);
    const newItems = newArray.map((image) => image.image_id || image);
    const removedIds = oldIds.filter((image) => !newItems.includes(image));
    const newIds = newItems.filter((image) => !oldIds.includes(image));
    return { removedIds, newIds };
};
/**
 * Checking removed and added features for venues
 *
 * @param {Object} oldFeatures previous list of features
 * @param {Object} newFeatures new list of features
 *
 * @returns {Array, Array}
 */
export const checkRemovedAndNewFeatures = (oldFeatures, newFeatures) => {
    const oldFeaturesIds = Object.keys(oldFeatures)
        .filter(id => oldFeatures[id])
        .map(id => parseInt(id, 10));
    const newFeaturesIds = Object.keys(newFeatures)
        .filter(id => newFeatures[id])
        .map(id => parseInt(id, 10));
    const removedIds = oldFeaturesIds.filter(id => !newFeaturesIds.includes(id));
    const newIds = newFeaturesIds.filter(id => !oldFeaturesIds.includes(id));
    return { removedIds, newIds };
};
/**
 * Converts a string from snake_case to camelCase.
 * This function specifically targets strings with underscores ('_') followed by a letter
 * and converts them to a camelCase format by removing the underscore and capitalizing the following letter.
 * It's typically used for converting object keys or variable names in programming contexts where camelCase
 * is the preferred notation, especially in JavaScript.
 *
 * @param {string} string - The snake_case string to be converted to camelCase.
 * @returns {string} The converted string in camelCase format.
 */
const toCamel = (string) => {
    return string.replace(/(_\w)/g, function (m) {
        return m[1].toUpperCase();
    });
};
/**
 * Converts all object keys from snake_case to camelCase.
 * This function is recursive and will process all nested objects and arrays.
 * It assumes the presence of 'isObject' and 'isArray' utility functions to check types,
 * and a 'toCamel' function to convert individual string keys from snake_case to camelCase.
 *
 * @param {Object|Array} object - The object (or array) with keys in snake_case format.
 * @returns {Object|Array} A new object (or array) with all keys converted to camelCase format.
 */
export const snakeToCamel = (object) => {
    if (isObject(object)) {
        const n = {};
        Object.keys(object).forEach((k) => {
            n[toCamel(k)] = snakeToCamel(object[k]);
        });
        return n;
    }
    else if (isArray(object)) {
        return object.map((i) => {
            return snakeToCamel(i);
        });
    }
    return object;
};
/**
 * Checking added, changed and removed services for venues or spaces
 *
 * @param {Array} oldServices previous array of services
 * @param {Array} newServices changed array of services
 *
 * @returns {Array, Array, Array}
 */
export const servicesInOperationOrder = (oldServices, newServices) => {
    const oldIds = oldServices.map((service) => service.system_service_id);
    const newIds = newServices.map((service) => service.system_service_id);
    const addedServices = newServices.filter((service) => !oldIds.includes(service.system_service_id));
    addedServices.forEach((service) => delete service.venue_service_id);
    const addedIds = addedServices.map((service) => service.system_service_id);
    const changedServices = newServices.filter((service) => !addedIds.includes(service.system_service_id));
    const removedIds = oldServices
        .filter((service) => !newIds.includes(service.system_service_id))
        .map((service) => service.venue_service_id);
    return { addedServices, changedServices, removedIds };
};
/**
 * Format time from string HH:mm into number of minutes
 * @param {string} time Time string (22:30)
 * @returns {number} Time in minutes (1350)
 */
const formatTime = (time) => {
    const [hours, minutes] = time.split(':');
    return parseInt(hours, 10) * 60 + parseInt(minutes, 10);
};
/**
 * Transform working time object into array of day slots
 *
 * @param {object} obj workingTime object
 *
 * @returns {Array}
 */
export const transformWorkingTime = (obj) => {
    const working = [];
    Object.values(obj).forEach((e, i) => {
        if (e.length) {
            const open_time = formatTime(e[0].open);
            const close_time = e[0].close === '00:00' ? 1440 : formatTime(e[0].close);
            working.push({
                week_day: i + 1,
                open_time,
                close_time
            });
        }
    });
    return working;
};
/**
 * Generates a GUID (Globally Unique Identifier), commonly used as a unique identifier in software applications.
 * The GUID is a 128-bit number, typically represented as a 32-character hexadecimal string, formatted in 5 groups
 * separated by hyphens, in the form 8-4-4-4-12.
 * This implementation uses random numbers to generate each part of the GUID, ensuring uniqueness with a high probability.
 *
 * @returns {string} A string representation of the GUID.
 */
export const generateGuid = () => {
    let result;
    let i;
    let j;
    result = '';
    for (j = 0; j < 32; j += 1) {
        if (j === 8 || j === 12 || j === 16 || j === 20)
            result = `${result}-`;
        i = Math.floor(Math.random() * 16)
            .toString(16)
            .toUpperCase();
        result += i;
    }
    return result;
};
/**
 * Generate working time object from array of day slots
 *
 * @param {array} arr workingTime array
 * @param {any} wt - An object representing the template structure for working time.
 *
 * @returns {Object}
 */
export const generateWorkingTime = (arr, wt) => {
    // Check if we are using object from local storage
    if (!Array.isArray(arr))
        return { ...arr };
    Object.values(wt).forEach((e, i) => {
        const isInArray = arr.find((s) => s.week_day === i + 1);
        if (isInArray) {
            e.push({
                open: reFormatTime(isInArray.open_time),
                close: reFormatTime(isInArray.close_time)
            });
        }
    });
    return wt;
};
/**
 * Get item from local storage
 * @param {string} key Key to search for (e.g. 'loginRedirectUrl')
 * @returns {any} Stored item (e.g. '/en/venue/1')
 */
export const getItemLocalStorage = (key) => {
    const json = localStorage.getItem(key);
    if (!json)
        return null;
    return JSON.parse(json);
};
/**
 * Adds tax to price
 * @param {number} price Price value (without tax, 50.00)
 * @param {number} percent Tax value (5)
 * @return {string} Price with tax as a string (55.00)
 */
export const calculatePriceWithTax = (price, percent) => {
    const tax = (price * percent) / 100;
    return (price + tax).toFixed(2);
};
/**
 * Adds tax and VAT to price
 * @param {number} price Price value (without tax, 50.00)
 * @param {number} percent Tax value (5)
 * @param {number} vat VAT value (10)
 * @return {string} Price with tax and  VAT as a string (65.00)
 */
export const calculatePriceWithTaxAndVAT = (price, percent, vat) => {
    const priceWithTax = price + (price * percent) / 100;
    const vatAmount = (priceWithTax * vat) / 100;
    return (priceWithTax + vatAmount).toFixed(2);
};
/**
 * Format working time object from array of day slots
 *
 * @param {array} venueOpeningHours workingTime array
 *
 * @returns {Object}
 */
export const formatVenueOpeningHours = (venueOpeningHours) => {
    if (!venueOpeningHours)
        return;
    const isLegacy = !!venueOpeningHours[0] && venueOpeningHours[0]['openTime'] !== undefined;
    const formattedVenueOpeningHours = {};
    let formattedWorkingTime;
    if (isLegacy) {
        const filteredNonNullWorkingTime = venueOpeningHours.filter((time) => time &&
            typeof time.weekDay === 'number' &&
            typeof time.openTime === 'number' &&
            typeof time.closeTime === 'number');
        formattedWorkingTime = filteredNonNullWorkingTime.map((time) => ({
            week_day: time.weekDay,
            open_time: time.openTime,
            close_time: time.closeTime
        }));
    }
    else {
        formattedWorkingTime = venueOpeningHours.filter((time) => !!time);
    }
    formattedWorkingTime.forEach((time) => {
        const dayOfWeekName = enumDayOfWeek.get(time.week_day) || enumDayOfWeek[1];
        formattedVenueOpeningHours[dayOfWeekName] = `${reFormatTime(time.open_time)} - ${reFormatTime(time.close_time)}`;
    });
    return formattedVenueOpeningHours;
};
/**
 * Validates an email address using a regular expression.
 * This function checks if the provided email address conforms to a standard format.
 * The regular expression used in this function is designed to match most common email formats, including
 * those with domain names and subdomains. It checks for general patterns found in email addresses like
 * segments separated by '@', domain parts with periods, and character restrictions before and after the '@' symbol.
 * Note: This regex does not cover some less common email address formats but works well for general use cases.
 *
 * @param {string} email - The email address to be validated.
 * @returns {boolean} True if the email address is valid according to the regex pattern, false otherwise.
 */
export const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
};
/**
 * Validates a phone number using a regular expression.
 * This function checks if the provided phone number conforms to a specific format.
 * The regular expression used here is designed to match phone numbers that start with a plus sign ('+')
 * followed by digits. This format is commonly used for international phone numbers.
 * It's important to note that this regex pattern is quite basic and might not cover all valid phone number formats.
 * More complex patterns might be required to validate phone numbers from specific countries or regions.
 *
 * @param {string} phone - The phone number to be validated.
 * @returns {boolean} True if the phone number is valid according to the regex pattern, false otherwise.
 */
export const validatePhone = (phone) => {
    const re = /^[+]\d+$/;
    return re.test(String(phone).toLowerCase());
};
