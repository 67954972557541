import { withAuthQueryHook } from '@/util/hooks';
import { computed, defineComponent, watch } from '@vue/composition-api';
import { useGetVenuesQuery } from '@/generated-types/graphql.types';
const getVenues = withAuthQueryHook(useGetVenuesQuery);
export default defineComponent({
    props: {
        value: {
            type: Number,
            default: null
        },
        spaces: {
            type: Array,
            default: () => []
        }
    },
    setup(props, context) {
        const { emit } = context;
        const { result } = getVenues({}, { clientId: 'legacy', enabled: true });
        const venues = computed(() => result.value?.viewerApiKey?.venuesGet);
        const preparedData = computed(() => {
            if (!venues.value)
                return [];
            return venues.value.map((venue) => {
                return {
                    value: venue.venueId,
                    label: venue.venueName
                };
            });
        });
        watch(() => [props.value, venues.value], () => {
            if (!props.value && venues.value?.length) {
                emit('input', venues?.value[0]?.venueId);
            }
        }, { immediate: true });
        const counter = computed(() => {
            if (!props.spaces)
                return 0;
            return new Set(props.spaces.map(space => space.space_id)).size;
        });
        return {
            counter,
            preparedData,
            venues
        };
    }
});
